import { Card, Typography } from "@mui/material";
import { CategoryScreenProps } from "./types";
import { GridCategory } from "./styles";
import { Category } from "../../pages/cinemaouting/types";
import {
  collection,
  getFirestore,
  onSnapshot,
  query,
} from "firebase/firestore";
import { app } from "../../firebase";
import { useEffect, useState } from "react";

export const CategoryScreen = (props: CategoryScreenProps) => {
  const { setCateIdSelected } = props;

  const db = getFirestore(app);
  const [categories, setCategories] = useState<Array<Category>>([]);

  useEffect(() => {
    let unsubscribe;
    if (db) {
      const categoryRef = query(collection(db, "cinema.category"));
      unsubscribe = onSnapshot(categoryRef, (querySnapshot) => {
        const newData: any[] = [];
        querySnapshot.forEach((doc) => {
          newData.push({ id: doc.id, ...doc.data() });
        });
        setCategories(newData);
      });
    }

    return unsubscribe;
  }, [db]);

  return (
    <>
      <Typography
        sx={{ textAlign: "center", mb: 2, fontWeight: "600" }}
        variant="h2"
      >
        หมวด
      </Typography>
      <GridCategory gap={2}>
        {categories.map((cate) => (
          <Card
            key={cate.id}
            sx={{ p: 4, cursor: "pointer" }}
            onClick={() => {
              setCateIdSelected(cate.id);
            }}
          >
            <Typography sx={{ textAlign: "center" }} variant="h3">
              {cate.name}
            </Typography>
          </Card>
        ))}
      </GridCategory>
    </>
  );
};
