import { useCallback, useRef, useState } from "react";

export const useTimer = (initialState = 0) => {
  const [elapsedTime, setElapsedTime] = useState(initialState);
  const [isRunning, setIsRunning] = useState(false);
  const countRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const [timeText, setTimeText] = useState("");
  const startTime = useRef(0);

  const handleStart = useCallback(() => {
    startTime.current = Date.now() - elapsedTime;
    countRef.current = setInterval(() => {
      const time = Date.now() - startTime.current;
      const second = Math.floor(time / 1000);
      setElapsedTime(time);
      const m = Math.floor((second % (60 * 60)) / 60)
        .toString()
        .padStart(2, "0");
      const s = (second % 60).toString().padStart(2, "0");

      setTimeText(`${m}:${s}`);
    }, 10);
    setIsRunning(true);
  }, [elapsedTime]);

  const handlePause = useCallback(() => {
    if (countRef.current) {
      clearInterval(countRef.current);
    }
    setIsRunning(false);
  }, []);

  const handlePlay = useCallback(() => {
    startTime.current = Date.now() - elapsedTime;
    countRef.current = setInterval(() => {
      const time = Date.now() - startTime.current;
      const second = Math.floor(time / 1000);
      setElapsedTime(time);
      const m = Math.floor((second % (60 * 60)) / 60)
        .toString()
        .padStart(2, "0");
      const s = (second % 60).toString().padStart(2, "0");

      setTimeText(`${m}:${s}`);
    }, 10);
    setIsRunning(true);
  }, [elapsedTime]);

  const handleReset = () => {
    if (countRef.current) {
      clearInterval(countRef.current);
    }
    setIsRunning(false);
    setElapsedTime(0);
  };

  return {
    timeText,
    elapsedTime,
    isRunning,
    handleStart,
    handlePause,
    handleReset,
    handlePlay,
  };
};
