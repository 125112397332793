import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const Wrapper = styled(Box)`
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
`;

export const BoxStyled = styled(Box)`
  width: 100%;
  display: grid;
  justify-items: center;
`;

export const ButtonStyled = styled.div`
  width: 90%;
  padding-top: 80px;
  padding-bottom: 80px;
  border: none;
  outline: none;
  color: #fff;
  background: #000;
  cursor: pointer;
  position: relative;
  text-align: center;
  z-index: 0;
  border-radius: 10px;

  &:before {
    content: "";
    background: linear-gradient(
      45deg,
      #ff0000,
      #ff7300,
      #fffb00,
      #48ff00,
      #00ffd5,
      #002bff,
      #7a00ff,
      #ff00c8,
      #ff0000
    );
    position: absolute;
    top: -8px;
    left: -8px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 16px);
    height: calc(100% + 16px);
    animation: glowing 20s linear infinite;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    border-radius: 20px;
  }

  &:active:after {
    background: transparent;
  }

  &:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000;
    left: 0;
    top: 0;
    border-radius: 10px;
  }

  @keyframes glowing {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 400% 0;
    }
    100% {
      background-position: 0 0;
    }
  }
`;
