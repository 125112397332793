import styled from "@emotion/styled";
import { Grid } from "@mui/material";

export const GridWord = styled(Grid)`
  min-height: calc(100vh - 32px);
`;

export const CurrentWord = styled.div`
  font-size: 150px;
  text-align: center;
  min-height: 70vh;
  display: grid;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  font-weight: 600;
`;

export const GridCategory = styled(Grid)`
  display: grid;

  grid-template-columns: 1fr;

  @media screen and (min-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (min-width: 900px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const ButtonStart = styled.button`
  font-size: 96px;
  font-family: inherit;
  background: rgba(255, 255, 255, 0.5);
  border: none;
  border-radius: 50%;
  min-width: 500px;
  min-height: 500px;
  max-width: 100%;
  height: auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  outline: none;
`;

export const TextTime = styled.div`
  font-family: verdana;
  font-size: 150px;
  font-weight: 600;
`;
