import { Button, Grid, Typography } from "@mui/material";
import punch_1 from "./files/punch_1.mp3";
import punch_2 from "./files/punch_2.mp3";
import first_blood from "./files/first_blood.mp3";
import double_kill from "./files/double_kill.mp3";
import muaythai_sound from "./files/muaythai.mp3";
import welcome_sound from "./files/welcome.mp3";
import poob_mur from "./files/poob_mur.mp3";
import snoop_dog from "./files/snoop_dog.mp3";
import hua_mor from "./files/hua_mor.mp3";
import { ButtonStyled, GridItem } from "../styles";
import { Link } from "react-router-dom";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

export default function Index() {
  const punch1 = new Audio(punch_1);
  const punch2 = new Audio(punch_2);
  const firstBlood = new Audio(first_blood);
  const doubleKill = new Audio(double_kill);
  const muaythai = new Audio(muaythai_sound);
  const welcome = new Audio(welcome_sound);
  const poobMur = new Audio(poob_mur);
  const snoopDog = new Audio(snoop_dog);
  const huaMor = new Audio(hua_mor);

  return (
    <Grid container>
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        padding={1}
      >
        <Link to={"/sound"}>
          <Button variant="text" startIcon={<ChevronLeft />}>
            เสียงรับมุก
          </Button>
        </Link>
        <Typography fontWeight={700}>2</Typography>
        <Link to={"/sound3"}>
          <Button variant="text" endIcon={<ChevronRight />}>
            เสียงน้าค่อม
          </Button>
        </Link>
      </Grid>
      <GridItem item xs={4}>
        <ButtonStyled
          variant="outlined"
          fullWidth
          onClick={() => {
            punch1.load();
            punch1.play();
          }}
        >
          ชก 1
        </ButtonStyled>
      </GridItem>
      <GridItem item xs={4}>
        <ButtonStyled
          variant="outlined"
          fullWidth
          onClick={() => {
            punch2.load();
            punch2.play();
          }}
        >
          ชก 2
        </ButtonStyled>
      </GridItem>
      <GridItem item xs={4}>
        <ButtonStyled
          variant="outlined"
          fullWidth
          onClick={() => {
            firstBlood.load();
            firstBlood.play();
          }}
        >
          First Blood
        </ButtonStyled>
      </GridItem>
      <GridItem item xs={4}>
        <ButtonStyled
          variant="outlined"
          fullWidth
          onClick={() => {
            doubleKill.load();
            doubleKill.play();
          }}
        >
          Double Kill
        </ButtonStyled>
      </GridItem>
      <GridItem item xs={4}>
        <ButtonStyled
          variant="outlined"
          fullWidth
          onClick={() => {
            muaythai.load();
            muaythai.play();
          }}
        >
          มวยไทย
        </ButtonStyled>
      </GridItem>
      <GridItem item xs={4}>
        <ButtonStyled
          variant="outlined"
          fullWidth
          onClick={() => {
            welcome.load();
            welcome.play();
          }}
        >
          ต้อนรับคณะตลก
        </ButtonStyled>
      </GridItem>
      <GridItem item xs={4}>
        <ButtonStyled
          variant="outlined"
          fullWidth
          onClick={() => {
            poobMur.load();
            poobMur.play();
          }}
        >
          ตบมือ
        </ButtonStyled>
      </GridItem>
      <GridItem item xs={4}>
        <ButtonStyled
          variant="outlined"
          fullWidth
          onClick={() => {
            snoopDog.load();
            snoopDog.play();
          }}
        >
          Snoop Dog
        </ButtonStyled>
      </GridItem>
      <GridItem item xs={4}>
        <ButtonStyled
          variant="outlined"
          fullWidth
          onClick={() => {
            huaMor.load();
            huaMor.play();
          }}
        >
          หัวหมอสุดๆ
        </ButtonStyled>
      </GridItem>
    </Grid>
  );
}
