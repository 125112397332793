import { Button, Grid, Typography } from "@mui/material";
import na_com_1 from "./files/na_com_1.mp3";
import na_com_2 from "./files/na_com_2.mp3";
import na_com_3 from "./files/na_com_3.mp3";
import na_com_4 from "./files/na_com_4.mp3";
import na_com_5 from "./files/na_com_5.mp3";
import na_com_6 from "./files/na_com_6.mp3";
import na_com_7 from "./files/na_com_7.mp3";
import na_com_8 from "./files/na_com_8.mp3";
import song_hoy_ha from "./files/song_hoy_ha.mp3";
import { ButtonStyled, GridItem } from "../styles";
import { Link } from "react-router-dom";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

export default function Index() {
  const naCom1 = new Audio(na_com_1);
  const naCom2 = new Audio(na_com_2);
  const naCom3 = new Audio(na_com_3);
  const naCom4 = new Audio(na_com_4);
  const naCom5 = new Audio(na_com_5);
  const naCom6 = new Audio(na_com_6);
  const naCom7 = new Audio(na_com_7);
  const naCom8 = new Audio(na_com_8);
  const songHoyHa = new Audio(song_hoy_ha);

  return (
    <Grid container>
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        padding={1}
      >
        <Link to={"/sound2"}>
          <Button variant="text" startIcon={<ChevronLeft />}>
            เสียงการแข่งขัน
          </Button>
        </Link>
        <Typography fontWeight={700}>3</Typography>
        <Link to={"/"}>
          <Button variant="text" endIcon={<ChevronRight />}>
            BACK HOME
          </Button>
        </Link>
      </Grid>
      <GridItem item xs={4}>
        <ButtonStyled
          variant="outlined"
          fullWidth
          onClick={() => {
            naCom1.load();
            naCom1.play();
          }}
        >
          ไม่คณามือ
        </ButtonStyled>
      </GridItem>
      <GridItem item xs={4}>
        <ButtonStyled
          variant="outlined"
          fullWidth
          onClick={() => {
            naCom2.load();
            naCom2.play();
          }}
        >
          ทางของพี่
        </ButtonStyled>
      </GridItem>
      <GridItem item xs={4}>
        <ButtonStyled
          variant="outlined"
          fullWidth
          onClick={() => {
            naCom3.load();
            naCom3.play();
          }}
        >
          นี่บ้าหรือป่าว
        </ButtonStyled>
      </GridItem>
      <GridItem item xs={4}>
        <ButtonStyled
          variant="outlined"
          fullWidth
          onClick={() => {
            naCom4.load();
            naCom4.play();
          }}
        >
          ขยี้ให้หนัก
        </ButtonStyled>
      </GridItem>
      <GridItem item xs={4}>
        <ButtonStyled
          variant="outlined"
          fullWidth
          onClick={() => {
            naCom5.load();
            naCom5.play();
          }}
        >
          แหม่ หอมฉุย
        </ButtonStyled>
      </GridItem>
      <GridItem item xs={4}>
        <ButtonStyled
          variant="outlined"
          fullWidth
          onClick={() => {
            naCom6.load();
            naCom6.play();
          }}
        >
          แทงให้มิด
        </ButtonStyled>
      </GridItem>
      <GridItem item xs={4}>
        <ButtonStyled
          variant="outlined"
          fullWidth
          onClick={() => {
            naCom7.load();
            naCom7.play();
          }}
        >
          ไอ้สัด
        </ButtonStyled>
      </GridItem>
      <GridItem item xs={4}>
        <ButtonStyled
          variant="outlined"
          fullWidth
          onClick={() => {
            naCom8.load();
            naCom8.play();
          }}
        >
          นโมตะสะ
        </ButtonStyled>
      </GridItem>
      <GridItem item xs={4}>
        <ButtonStyled
          variant="outlined"
          fullWidth
          onClick={() => {
            songHoyHa.load();
            songHoyHa.play();
          }}
        >
          โหยหา
        </ButtonStyled>
      </GridItem>
    </Grid>
  );
}
