import { createTheme } from "@mui/material";
import "@fontsource/prompt";

export const theme = createTheme({
  typography: {
    fontFamily: [
      "Prompt",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  palette: {
    primary: {
      light: "#000000",
      main: "#000000",
      dark: "#000000",
      contrastText: "#FFF",
    },
    secondary: {
      light: "#f433ff",
      main: "#f433ff",
      dark: "#f433ff",
      contrastText: "#FFF",
    },
  },
});
