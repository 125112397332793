import { Box, Grid, TextField, Typography } from "@mui/material";
import InfoHook from "./info.hook";
import Congratulation from "../../../components/congratulation";

export default function Info() {
  const { name, handleChange, handleSubmit } = InfoHook();

  return (
    <form onSubmit={handleSubmit}>
      <Congratulation />
      <Box className="centered" sx={{ minWidth: 290 }}>
        <Typography variant="caption" fontWeight={600}>
          OUTTING
        </Typography>
        <div className="sign">
          IN
          <span className="fast-flicker">N</span>OVA
          <span className="flicker">T</span>I
          <span className="fast-flicker">O</span>N
        </div>
        <Box sx={{ mt: 20, mb: 5 }}>
          <TextField
            fullWidth
            value={name}
            placeholder="ชื่อเล่น"
            variant="standard"
            helperText="ใส่ชื่อก่อนนะจ๊ะ จุฟๆ"
            onChange={handleChange}
          />
        </Box>
        <Grid container justifyContent={"center"}>
          <button
            className="glowing-btn"
            type="submit"
            disabled={!Boolean(name)}
          >
            <span className="glowing-txt">
              C<span className="faulty-letter">O</span>NTI
              <span className="faulty-letter">N</span>UE
            </span>
          </button>
        </Grid>
      </Box>
    </form>
  );
}
