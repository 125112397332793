import { Container } from "@mui/material";
import { WordScreen } from "../../components/cinema/WordScreen";
import { CategoryScreen } from "../../components/cinema/CategoryScreen";
import { useAtom } from "jotai";
import { cateIdSelectedAtom } from "./state";

const CinemaOuting = () => {
  const [cateIdSelected, setCateIdSelected] = useAtom(cateIdSelectedAtom);

  return (
    <Container sx={{ pt: 2, mx: "auto" }}>
      {!cateIdSelected ? (
        <CategoryScreen setCateIdSelected={setCateIdSelected} />
      ) : (
        <WordScreen
          cateIdSelected={cateIdSelected}
          setCateIdSelected={setCateIdSelected}
        />
      )}
    </Container>
  );
};

export default CinemaOuting;
