import { atom as atomJotai } from "jotai";

// ref: https://jotai.org/docs/guides/persistence#a-helper-function-with-localstorage-and-json-parse
export const atomWithLocalStorage = <T>(key: string, initialValue: T) => {
  const getInitialValue = (): T => {
    if (typeof window === "undefined") {
      return initialValue;
    }

    const item = localStorage.getItem(key);

    if (item !== null) {
      try {
        return JSON.parse(item) as T;
      } catch {
        return initialValue;
      }
    }
    return initialValue;
  };
  const baseAtom = atomJotai(getInitialValue());
  const derivedAtom = atomJotai(
    (get) => get(baseAtom),
    (get, set, update) => {
      const nextValue =
        typeof update === "function" ? update(get(baseAtom)) : update;

      set(baseAtom, nextValue);
      localStorage.setItem(key, JSON.stringify(nextValue));
    }
  );

  return derivedAtom;
};
