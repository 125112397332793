import { Typography } from "@mui/material";
import { useTimer } from "./hooks/useTimer";
import { Ref, useImperativeHandle } from "react";

export type TimeRef = {
  isRunning: boolean;
  start: () => void;
  pause: () => void;
  play: () => void;
};

export type TimeProps = {
  itemRef: Ref<TimeRef>;
};

export const Time = (props: TimeProps) => {
  const { timeText, isRunning, handleStart, handlePause, handlePlay } =
    useTimer();

  useImperativeHandle(
    props.itemRef,
    () => ({
      isRunning: isRunning,
      start: handleStart,
      pause: handlePause,
      play: handlePlay,
    }),
    [isRunning, handleStart, handlePause, handlePlay]
  );

  return (
    <Typography variant="h2" fontFamily="verdana" fontWeight="600">
      {timeText}
    </Typography>
  );
};
