import {
  getFirestore,
  onSnapshot,
  collection,
  query,
  deleteDoc,
  doc,
  setDoc,
  where,
  getDocs,
} from "firebase/firestore";
import { app } from "../../../firebase";
import { useEffect, useState } from "react";
import { QuestionType } from "../../../types";
import dayjs from "dayjs";
import { toast } from "react-toastify";

export default function QuestionHook() {
  const [data, setData] = useState<Array<QuestionType>>([]);
  const [text, setText] = useState<string>("");
  const db = getFirestore(app);

  useEffect(() => {
    if (db) {
      const ref = query(collection(db, "question"));
      onSnapshot(ref, (querySnapshot) => {
        const newData: any[] = [];
        querySnapshot.forEach((doc) => {
          newData.push({ id: doc.id, ...doc.data() });
        });
        setData(newData);
      });
    }
  }, [db]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    toast("successfully add question");
    const data: QuestionType = { text };
    const id = dayjs().valueOf();

    const ref = doc(db, "question", id.toString());
    await setDoc(ref, data).then((res) => {
      setText("");
    });
  };

  const handleSelect = async (id: string | undefined) => {
    toast("successfully question");
    const questionRef = query(
      collection(db, "question"),
      where("enabled", "==", true)
    );
    const querySnapshot = await getDocs(questionRef);
    querySnapshot.forEach(async (document) => {
      await setDoc(
        doc(db, "question", document.id),
        { enabled: false },
        { merge: true }
      );
    });

    const ref = doc(db, "question", id || "");
    await setDoc(ref, { enabled: true }, { merge: true });
  };

  const handleDelete = async (id: string | undefined) => {
    toast("successfully delete");
    deleteDoc(doc(db, "question", id || ""));
  };

  return { data, text, handleChange, handleSubmit, handleSelect, handleDelete };
}
