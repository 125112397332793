import { useRef, useState } from "react";

export const useCountDown = ({
  initialState = 3,
  onFinish,
}: {
  initialState: number;
  onFinish: () => void;
}) => {
  const [elapsedTime, setElapsedTime] = useState(initialState);
  const [isRunning, setIsRunning] = useState(false);
  const countRef = useRef<ReturnType<typeof setInterval> | null>(null);

  const handleStart = () => {
    const startTime = Date.now() + elapsedTime;
    countRef.current = setInterval(() => {
      if (startTime - Date.now() < 5) {
        onFinish();
        if (countRef.current) {
          clearInterval(countRef.current);
        }
      }
      setElapsedTime(startTime - Date.now());
    }, 10);
    setIsRunning(true);
  };

  const handlePause = () => {
    if (countRef.current) {
      clearInterval(countRef.current);
    }
    setIsRunning(false);
  };

  const handleReset = () => {
    if (countRef.current) {
      clearInterval(countRef.current);
    }
    setIsRunning(false);
    setElapsedTime(initialState);
  };

  return { elapsedTime, isRunning, handleStart, handlePause, handleReset };
};
