import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCAv3IXEoNKi1ZffOr3-IXKitPoObpV3_0",
  authDomain: "entertainment-abdaf.firebaseapp.com",
  projectId: "entertainment-abdaf",
  storageBucket: "entertainment-abdaf.appspot.com",
  messagingSenderId: "48933310899",
  appId: "1:48933310899:web:d4f984ebdd63e27ba1fbc6",
  measurementId: "G-3Q5153QHGR",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
