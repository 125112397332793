import { Button, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function Menu() {
  return (
    <Grid container flexDirection={"column"} spacing={1} padding={3}>
      <Grid item xs={12}>
        <Typography fontWeight={700}>MENU</Typography>
      </Grid>
      <Grid item xs={12}>
        <Link to={"/sound"}>
          <Button variant="outlined" fullWidth>
            SOUND MOOK
          </Button>
        </Link>
      </Grid>
      <Grid item xs={12}>
        <Link to={"/sound2"}>
          <Button variant="outlined" fullWidth>
            SOUND SPORT
          </Button>
        </Link>
      </Grid>
      <Grid item xs={12}>
        <Link to={"/sound3"}>
          <Button variant="outlined" fullWidth>
            SOUND NA COM
          </Button>
        </Link>
      </Grid>
      <Grid item xs={12}>
        <Typography fontWeight={700}>GAME</Typography>
      </Grid>
      <Grid item xs={12}>
        <Link to={"/game"}>
          <Button variant="outlined" fullWidth>
            Game
          </Button>
        </Link>
      </Grid>
      <Grid item xs={12}>
        <Link to={"/game/info"}>
          <Button variant="outlined" fullWidth>
            Game Info
          </Button>
        </Link>
      </Grid>
      <Grid item xs={12}>
        <Link to={"/game/dashboard"}>
          <Button variant="outlined" fullWidth>
            Game Dashboard
          </Button>
        </Link>
      </Grid>
      <Grid item xs={12}>
        <Link to={"/game/question"}>
          <Button variant="outlined" fullWidth>
            Game Question
          </Button>
        </Link>
      </Grid>
      <Grid item xs={12}>
        <Typography fontWeight={700}>CINEMA OUTING</Typography>
      </Grid>
      <Grid item xs={12}>
        <Link to={"/cinemaouting"}>
          <Button variant="outlined" fullWidth>
            Cinema Outing
          </Button>
        </Link>
      </Grid>
      <Grid item xs={12}>
        <Link to={"/cinemaouting/setup"}>
          <Button variant="outlined" fullWidth>
            Cinema Outing Setup
          </Button>
        </Link>
      </Grid>
    </Grid>
  );
}
