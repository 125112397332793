import { Routes, Route } from "react-router-dom";
import { theme } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Menu from "./pages/menu";
import Game from "./pages/game";
import Info from "./pages/game/info";
import Dashboard from "./pages/game/dashboard";
import Question from "./pages/game/question";
import CinemaOuting from "./pages/cinemaouting";
import CinemaOutingSetup from "./pages/cinemaouting/setup";
import Mook from "./pages/sound/mook";
import Sport from "./pages/sound/sport";
import NaCom from "./pages/sound/naCom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route path="/" element={<Menu />} />
        <Route path="game" element={<Game />} />
        <Route path="game/info" element={<Info />} />
        <Route path="game/dashboard" element={<Dashboard />} />
        <Route path="game/question" element={<Question />} />
        <Route path="cinemaouting" element={<CinemaOuting />} />
        <Route path="cinemaouting/setup" element={<CinemaOutingSetup />} />
        <Route path="sound" element={<Mook />} />
        <Route path="sound2" element={<Sport />} />
        <Route path="sound3" element={<NaCom />} />
      </Routes>
      <ToastContainer
        position="top-left"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </ThemeProvider>
  );
}

export default App;
