import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import QuestionHook from "./question.hook";

export default function Question() {
  const { data, text, handleChange, handleSubmit, handleSelect, handleDelete } =
    QuestionHook();

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography fontWeight={600}>ตั้งคำถาม</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={text}
                  placeholder="ระบุ"
                  variant="standard"
                  helperText="ใส่โจทย์ของคุณ"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="large"
                  disabled={!Boolean(text)}
                  type="submit"
                  sx={{ width: 207.5 }}
                >
                  OK
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid item xs={12} md={9}>
          <Typography fontWeight={600}>คำถาม</Typography>
          <List>
            {data.map((item, index) => {
              const { id, text } = item;
              return (
                <Grid container alignItems="center">
                  <Grid item xs={10}>
                    <ListItemButton
                      onClick={() => handleSelect(id)}
                      sx={{
                        backgroundColor: "rgba(255,255,255,0.4)",
                        borderRadius: 5,
                        p: 2,
                        mb: 1,
                      }}
                    >
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar sx={{ backgroundColor: "#000" }}>
                            <Typography>{index + 1}</Typography>
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={text} />
                      </ListItem>
                    </ListItemButton>
                  </Grid>
                  <Grid container item xs={2} justifyContent="center">
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleDelete(id)}
                    >
                      <DeleteIcon sx={{ color: "#000" }} />
                    </IconButton>
                  </Grid>
                </Grid>
              );
            })}
          </List>
        </Grid>
      </Grid>
    </Box>
  );
}
