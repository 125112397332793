import {
  collection,
  getFirestore,
  onSnapshot,
  query,
  deleteDoc,
  updateDoc,
  setDoc,
  doc,
} from "firebase/firestore";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { app } from "../../firebase";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { Category, CategoryForm } from "./types";

const CinemaOutingSetup = () => {
  const db = getFirestore(app);
  const [open, setOpen] = useState(false);
  const [categories, setCategories] = useState<Array<Category>>([]);

  const { control, reset, setValue, register, handleSubmit } =
    useForm<CategoryForm>({
      defaultValues: { list: [{ value: "" }] },
    });

  const { fields, append, remove } = useFieldArray({ control, name: "list" });

  const editingId = useWatch({ control, name: "id" });

  useEffect(() => {
    let unsubscribe;
    if (db) {
      const categoryRef = query(collection(db, "cinema.category"));
      unsubscribe = onSnapshot(categoryRef, (querySnapshot) => {
        const newData: any[] = [];
        querySnapshot.forEach((doc) => {
          newData.push({ id: doc.id, ...doc.data() });
        });
        setCategories(newData);
      });
    }

    return unsubscribe;
  }, [db]);

  const onSubmit = async (values: CategoryForm) => {
    if (!values.id) {
      const newId = new Date().valueOf().toString();
      await setDoc(doc(db, "cinema.category", newId), {
        name: values.name,
        list: values.list.map((item) => item.value),
      });
      setValue("id", newId);
    } else {
      await updateDoc(doc(db, "cinema.category", values.id), {
        name: values.name,
        list: values.list.map((item) => item.value),
      });
    }
  };

  const handleAddRow = () => {
    append({ value: "" });
  };

  return (
    <Container sx={{ pt: 4, mx: "auto" }}>
      <Grid display="flex" flexDirection="row" gap={2}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" component="div">
              หมวด
            </Typography>
            <List>
              {categories.map((cate) => (
                <ListItem
                  key={cate.id}
                  secondaryAction={
                    <IconButton
                      onClick={() => {
                        reset({
                          id: cate.id,
                          name: cate.name,
                          list: cate.list.map((item) => ({ value: item })),
                        });
                      }}
                      edge="end"
                      aria-label="delete"
                    >
                      <EditIcon />
                    </IconButton>
                  }
                >
                  <ListItemText
                    primary={cate.name}
                    secondary={`จำนวน ${cate.list.length} คำ`}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid lg={12} item>
              {editingId ? "แก้ไขข้อมูล" : "เพิ่มข้อมูล"}
            </Grid>
            <Grid lg={12} item>
              <TextField
                placeholder="ชื่อหมวด"
                {...register("name", { required: true })}
              />
              <List>
                <Button
                  sx={{ mt: 2 }}
                  variant="contained"
                  fullWidth
                  size="large"
                  onClick={handleAddRow}
                >
                  เพิ่มคำ
                </Button>
                {fields.map((field, index) => (
                  <ListItem key={field.id}>
                    <TextField
                      placeholder="คำ"
                      {...register(`list.${index}.value`, { required: true })}
                    />
                    <IconButton onClick={() => remove(index)}>
                      <DeleteIcon sx={{ color: "#000" }} />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Button
              sx={{ mt: 2 }}
              type="submit"
              variant="contained"
              fullWidth
              size="large"
            >
              บันทึก
            </Button>
            <Button
              sx={{ mt: 2 }}
              fullWidth
              size="large"
              onClick={() => reset({ name: "", list: [] })}
            >
              ล้าง
            </Button>
            {editingId ? (
              <Button
                sx={{ mt: 2 }}
                fullWidth
                size="large"
                color="error"
                onClick={() => setOpen(true)}
              >
                ลบ
              </Button>
            ) : null}
          </form>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{ sx: { minWidth: 320 } }}
      >
        <DialogTitle id="responsive-dialog-title">ลบข้อมูล</DialogTitle>
        <DialogContent>
          <DialogContentText>ยืนยันลบข้อมูล</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setOpen(false)}>
            ยกเลิก
          </Button>
          <Button
            color="error"
            onClick={() => {
              deleteDoc(doc(db, "cinema.category", editingId)).then(() => {
                setOpen(false);
                reset({ name: "", list: [] });
              });
            }}
          >
            ตกลง
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default CinemaOutingSetup;
