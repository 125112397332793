import {
  getFirestore,
  onSnapshot,
  collection,
  query,
  getDocs,
  doc,
  where,
  setDoc,
} from "firebase/firestore";
import { app } from "../../../firebase";
import { useEffect, useState } from "react";
import { DataType, QuestionType } from "../../../types";
import { toast } from "react-toastify";

export default function DashboardHook() {
  const [data, setData] = useState<Array<DataType>>([]);
  const [question, setQuestion] = useState<Array<QuestionType>>([]);
  const db = getFirestore(app);

  useEffect(() => {
    if (db) {
      const usersRef = query(
        collection(db, "users"),
        where("time", "!=", false)
      );
      onSnapshot(usersRef, (querySnapshot) => {
        const newData: any[] = [];
        querySnapshot.forEach((doc) => {
          newData.push({ id: doc.id, ...doc.data() });
        });
        setData(newData);
      });
    }
  }, [db]);

  useEffect(() => {
    if (db) {
      const questionRef = query(
        collection(db, "question"),
        where("enabled", "==", true)
      );
      onSnapshot(questionRef, (querySnapshot) => {
        const newData: any[] = [];
        querySnapshot.forEach((doc) => {
          newData.push({ id: doc.id, ...doc.data() });
        });
        setQuestion(newData);
      });
    }
  }, [db]);

  const handleScore = async (id: string | undefined, score: number) => {
    toast("successfully add score");
    await setDoc(
      doc(db, "users", id || ""),
      { score: score + 1 },
      { merge: true }
    );
  };

  const handleStart = async () => {
    toast("successfully start");
    const usersRef = query(collection(db, "users"));
    const querySnapshot = await getDocs(usersRef);
    querySnapshot.forEach(async (document) => {
      await setDoc(
        doc(db, "users", document.id),
        { enabled: true },
        { merge: true }
      );
    });
  };

  const handleClear = async () => {
    toast("successfully clear");
    const usersRef = query(collection(db, "users"));
    const querySnapshot = await getDocs(usersRef);
    querySnapshot.forEach(async (document) => {
      await setDoc(
        doc(db, "users", document.id),
        { time: false },
        { merge: true }
      );
    });
  };

  return { data, question, handleScore, handleStart, handleClear };
}
