import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { app } from "../../firebase";
import dayjs from "dayjs";
import {
  onSnapshot,
  getFirestore,
  setDoc,
  doc,
  query,
  collection,
  where,
} from "firebase/firestore";
import { DataType, QuestionType } from "../../types";

export default function GameHook() {
  const uuid = localStorage.getItem("uuid");
  const navigate = useNavigate();
  const [click, setClick] = useState<number>(0);
  const [data, setData] = useState<DataType>();
  const [question, setQuestion] = useState<Array<QuestionType>>([]);
  const db = getFirestore(app);

  useEffect(() => {
    navigate(uuid ? "/game" : "game/info");
  }, [uuid, navigate]);

  useEffect(() => {
    if (db && uuid) {
      const finish = async () => {
        const usersRef = doc(db, "users", uuid);
        const time = dayjs().valueOf();
        await setDoc(usersRef, { time, enabled: false }, { merge: true });
      };
      if (click >= 50) finish();
    }
  }, [click, db, uuid]);

  useEffect(() => {
    if (data?.enabled === true) {
      setClick(0);
    }
  }, [data]);

  useEffect(() => {
    if (uuid && db) {
      const usersRef = doc(db, "users", uuid);
      onSnapshot(usersRef, (doc) => {
        const data = doc.data();
        setData(data);
      });
    }
  }, [db, uuid]);

  useEffect(() => {
    if (db) {
      const questionRef = query(
        collection(db, "question"),
        where("enabled", "==", true)
      );
      onSnapshot(questionRef, (querySnapshot) => {
        const newData: any[] = [];
        querySnapshot.forEach((doc) => {
          newData.push({ id: doc.id, ...doc.data() });
        });
        setQuestion(newData);
      });
    }
  }, [db]);

  const handleClick = () => {
    if (data?.enabled) {
      setClick((prevState) => prevState + 1);
    }
  };

  return { click, data, question, handleClick };
}
