import { doc, getFirestore, setDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { app } from "../../../firebase";
import { DataType } from "../../../types";

export default function InfoHook() {
  const myUuid = localStorage.getItem("uuid");
  const myName = localStorage.getItem("name");
  const uuid = uuidv4();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const db = getFirestore(app);

  useEffect(() => {
    if (myName) {
      setName(myName);
    }
  }, [myName]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setName(e.target.value);
  };

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    const data: DataType = { name };
    const id = myUuid ? myUuid : uuid;

    const usersRef = doc(db, "users", id);
    await setDoc(usersRef, data, { merge: true }).then(() => {
      localStorage.setItem("name", name);
      !myUuid && localStorage.setItem("uuid", uuid);
    });

    return navigate("/game");
  };

  return { name, handleChange, handleSubmit };
}
