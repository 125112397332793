import { Avatar, Backdrop, Box, Grid, Typography } from "@mui/material";
import GameHook from "./game.hook";
import { BoxStyled, ButtonStyled, Wrapper } from "./styles";
import { Link } from "react-router-dom";
import Congratulation from "../../components/congratulation";
import { PeopleAlt } from "@mui/icons-material";

export default function Game() {
  const { click, data, question, handleClick } = GameHook();

  return (
    <Wrapper>
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={click >= 50}
      >
        <Congratulation />
      </Backdrop>
      <Grid container justifyContent="flex-end" sx={{ p: 2 }}>
        <Grid item xs={6}>
          <Typography sx={{ mb: 1 }}>score: {data?.score || 0}</Typography>
          {question.map(({ text }) => (
            <Typography fontWeight={600}>{text}</Typography>
          ))}
        </Grid>
        <Grid item xs={6}>
          <Grid
            container
            justifyContent="flex-end"
            alignItems={"center"}
            flexWrap={"nowrap"}
          >
            <Box
              sx={{
                display: "flex",
                gap: 1,
                alignItems: "center",
                backgroundColor: "rgba(255,255,255,0.4)",
                borderRadius: 5,
                p: 2,
              }}
            >
              <Link to={"/game/info"}>
                <Avatar sx={{ width: 30, height: 30, backgroundColor: "#000" }}>
                  <PeopleAlt fontSize={"small"} />
                </Avatar>
              </Link>
              <Link to={"/game/info"}>
                {data?.name ? (
                  <Typography fontWeight={600}>{data?.name}</Typography>
                ) : (
                  "เปลี่ยนชื่อ"
                )}
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <BoxStyled className="centered">
        <Typography
          className="countClick"
          title={click.toString()}
          fontWeight={600}
          sx={{
            fontSize: 120,
            mb: 1,
            textShadow: "rgb(244,51,255) 0px 0px 10px",
          }}
        >
          {click}
        </Typography>
        <ButtonStyled onClick={handleClick}>
          <Typography variant="h3" fontWeight={600} className="clickMe">
            CLICK ME
          </Typography>
        </ButtonStyled>
        <Typography variant="subtitle2" sx={{ mt: 3 }}>
          50 POINT TO FINISH
        </Typography>
      </BoxStyled>
    </Wrapper>
  );
}
