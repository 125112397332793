import styled from "@emotion/styled";
import { Button, Grid } from "@mui/material";

export const GridItem = styled(Grid)`
  height: calc(33.33vh - 18px);
`;

export const ButtonStyled = styled(Button)`
  height: 100%;
  font-size: 20px;
  font-weight: 700;
  border-radius: 0;
`;
