import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import DashboardHook from "./dashboard.hook";
import { Box, Button, Grid, IconButton } from "@mui/material";
import { PlusOne } from "@mui/icons-material";
import { ToastContainer } from "react-toastify";

export default function Score() {
  const { data, question, handleScore, handleStart, handleClear } =
    DashboardHook();

  return (
    <>
      <ToastContainer
        position="top-left"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <Grid container>
        <Grid item xs={9}>
          <Box className="centered" sx={{ position: "fixed" }}>
            {question.map(({ text }) => (
              <Typography align="center" variant="h1" fontWeight={600}>
                {text}
              </Typography>
            ))}
          </Box>
        </Grid>
        <Grid item xs={3} sx={{ p: 2 }}>
          <List>
            {data.map((item, index) => {
              const { id, name, score } = item;
              const point = typeof score === "undefined" ? 0 : score;
              return (
                <ListItem
                  sx={{
                    backgroundColor: "rgba(255,255,255,0.4)",
                    borderRadius: 5,
                    p: 2,
                    mb: 1,
                  }}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleScore(id, point)}
                    >
                      <Avatar sx={{ width: 30, height: 30 }}>
                        <PlusOne fontSize="small" sx={{ color: "#000" }} />
                      </Avatar>
                    </IconButton>
                  }
                >
                  <ListItemAvatar>
                    <Avatar sx={{ backgroundColor: "#000" }}>
                      <Typography>{index + 1}</Typography>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={name} />
                </ListItem>
              );
            })}
          </List>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="center"
        spacing={2}
        sx={{
          position: "fixed",
          bottom: 30,
        }}
      >
        <Grid item>
          <Button variant="outlined" onClick={handleClear}>
            CLEAR
          </Button>
        </Grid>
        <Grid item>
          <Button variant="outlined" onClick={handleStart}>
            START
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
