import { Button, Grid, Typography } from "@mui/material";
import mook_1 from "./files/mook_1.mp3";
import mook_2 from "./files/mook_2.mp3";
import mook_3 from "./files/mook_3.mp3";
import mook_4 from "./files/mook_4.mp3";
import mook_5 from "./files/mook_5.mp3";
import mook_6 from "./files/mook_6.mp3";
import boxing_bell from "./files/boxing_bell.mp3";
import sound_horn from "./files/horn.mp3";
import ok_saekdkhaaw from "./files/ok_saekdkhaaw.mp3";
import { ButtonStyled, GridItem } from "../styles";
import { Link } from "react-router-dom";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

export default function Index() {
  const mook1 = new Audio(mook_1);
  const mook2 = new Audio(mook_2);
  const mook3 = new Audio(mook_3);
  const mook4 = new Audio(mook_4);
  const mook5 = new Audio(mook_5);
  const mook6 = new Audio(mook_6);
  const boxingBell = new Audio(boxing_bell);
  const horn = new Audio(sound_horn);
  const okSaekdkhaaw = new Audio(ok_saekdkhaaw);

  return (
    <Grid container>
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        padding={1}
      >
        <Link to={"/"}>
          <Button variant="text" startIcon={<ChevronLeft />}>
            BACK HOME
          </Button>
        </Link>
        <Typography fontWeight={700}>1</Typography>
        <Link to={"/sound2"}>
          <Button variant="text" endIcon={<ChevronRight />}>
            เสียงการแข่งขัน
          </Button>
        </Link>
      </Grid>
      <GridItem item xs={4}>
        <ButtonStyled
          variant="outlined"
          fullWidth
          onClick={() => {
            mook1.load();
            mook1.play();
          }}
        >
          รับมุก 1
        </ButtonStyled>
      </GridItem>
      <GridItem item xs={4}>
        <ButtonStyled
          variant="outlined"
          fullWidth
          onClick={() => {
            mook2.load();
            mook2.play();
          }}
        >
          รับมุก 2
        </ButtonStyled>
      </GridItem>
      <GridItem item xs={4}>
        <ButtonStyled
          variant="outlined"
          fullWidth
          onClick={() => {
            mook3.load();
            mook3.play();
          }}
        >
          รับมุก 3
        </ButtonStyled>
      </GridItem>
      <GridItem item xs={4}>
        <ButtonStyled
          variant="outlined"
          fullWidth
          onClick={() => {
            mook4.load();
            mook4.play();
          }}
        >
          รับมุก 4
        </ButtonStyled>
      </GridItem>
      <GridItem item xs={4}>
        <ButtonStyled
          variant="outlined"
          fullWidth
          onClick={() => {
            mook5.load();
            mook5.play();
          }}
        >
          รับมุก 5
        </ButtonStyled>
      </GridItem>
      <GridItem item xs={4}>
        <ButtonStyled
          variant="outlined"
          fullWidth
          onClick={() => {
            mook6.load();
            mook6.play();
          }}
        >
          รับมุกใหญ่
        </ButtonStyled>
      </GridItem>
      <GridItem item xs={4}>
        <ButtonStyled
          variant="outlined"
          fullWidth
          onClick={() => {
            boxingBell.load();
            boxingBell.play();
          }}
        >
          ระฆังมวย
        </ButtonStyled>
      </GridItem>
      <GridItem item xs={4}>
        <ButtonStyled
          variant="outlined"
          fullWidth
          onClick={() => {
            horn.load();
            horn.play();
          }}
        >
          เสียงแตร
        </ButtonStyled>
      </GridItem>
      <GridItem item xs={4}>
        <ButtonStyled
          variant="outlined"
          fullWidth
          onClick={() => {
            okSaekdkhaaw.load();
            okSaekdkhaaw.play();
          }}
        >
          OK สเก็ดข่าว
        </ButtonStyled>
      </GridItem>
    </Grid>
  );
}
