import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { WordScreenProps } from "./types";
import { app } from "../../firebase";
import { useCallback, useEffect, useRef, useState } from "react";
import { Category } from "../../pages/cinemaouting/types";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { ButtonStart, CurrentWord, GridWord, TextTime } from "./styles";
import { useAtom } from "jotai";
import { currentWordIndexAtom } from "./state";
import { useCountDown } from "./hooks/useCountDown";
import { Time, TimeRef } from "./Time";

export const WordScreen = (props: WordScreenProps) => {
  const { cateIdSelected, setCateIdSelected } = props;
  const db = getFirestore(app);
  const [cate, setCate] = useState<Category | null>(null);
  const [currentWordIndex, setCurrentWordIndex] = useAtom(currentWordIndexAtom);

  const [isStart, setIsStart] = useState(false);
  const [isPause, setIsPause] = useState(false);
  const timerRef = useRef<TimeRef>(null);
  const {
    isRunning: isRunningCountDown,
    handleStart: startCountDown,
    elapsedTime: countDown,
  } = useCountDown({
    initialState: 3000,
    onFinish: () => {
      timerRef.current?.start();
    },
  });

  const handlePrevious = useCallback(() => {
    if (
      !timerRef.current?.isRunning ||
      isPause ||
      !isStart ||
      !cate?.list.length
    ) {
      return;
    }

    if (currentWordIndex <= 0) {
      return;
    }

    setCurrentWordIndex((prevState: number) => prevState - 1);
  }, [
    cate?.list.length,
    currentWordIndex,
    isPause,
    isStart,
    setCurrentWordIndex,
  ]);

  const handleNext = useCallback(() => {
    if (
      !timerRef.current?.isRunning ||
      isPause ||
      !isStart ||
      !cate?.list.length
    ) {
      return;
    }

    if (currentWordIndex + 1 >= cate?.list.length) {
      return;
    }

    setCurrentWordIndex((prevState: number) => prevState + 1);
  }, [
    cate?.list.length,
    currentWordIndex,
    isPause,
    isStart,
    setCurrentWordIndex,
  ]);

  const handlePause = useCallback(() => {
    if (!isStart || (isRunningCountDown && countDown >= 5)) {
      return;
    }

    if (timerRef.current?.isRunning) {
      timerRef.current?.pause();
      setIsPause(true);
    } else {
      timerRef.current?.play();
      setIsPause(false);
    }
  }, [countDown, isRunningCountDown, isStart]);

  const handleStartGame = useCallback(() => {
    setIsStart(true);
    startCountDown();
  }, [startCountDown]);

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      switch (event.key) {
        case "ArrowLeft":
          handlePrevious();
          break;

        case "ArrowRight":
          handleNext();
          break;

        case " ":
          if (isStart) {
            handlePause();
          } else {
            handleStartGame();
          }
          break;

        default:
          break;
      }
    },
    [handleNext, handlePause, handlePrevious, handleStartGame, isStart]
  );

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);

    return () => window.removeEventListener("keydown", onKeyDown);
  }, [onKeyDown, currentWordIndex]);

  useEffect(() => {
    let unsubscribe;
    if (db && cateIdSelected) {
      const cateRef = doc(db, "cinema.category", cateIdSelected);

      unsubscribe = onSnapshot(cateRef, (querySnapshot) => {
        if (querySnapshot.exists()) {
          const data = querySnapshot.data() as Category;
          setCate({ ...data });
        }
      });
    }

    return unsubscribe;
  }, [db, cateIdSelected]);

  const handleBackToHome = () => {
    setCateIdSelected(null);
    setCurrentWordIndex(0);
  };

  if (!cateIdSelected) {
    return null;
  }

  const renderWord = () => {
    if (isPause) {
      return <ButtonStart onClick={handlePause}>พัก</ButtonStart>;
    }
    if (isRunningCountDown && countDown >= 5) {
      return <TextTime>{(Math.abs(countDown) / 1000).toFixed(2)}</TextTime>;
    }

    if (isStart) {
      return cate?.list[currentWordIndex];
    }

    return <ButtonStart onClick={handleStartGame}>เริ่ม</ButtonStart>;
  };

  return (
    <GridWord>
      <Grid display="flex" alignItems="center" justifyContent="space-between">
        <Button
          size="large"
          onClick={handleBackToHome}
          startIcon={<ChevronLeft />}
        >
          หน้าหลัก
        </Button>
        <Time itemRef={timerRef} />
      </Grid>
      <CurrentWord>{renderWord()}</CurrentWord>
      <Grid display="flex" gap={4} justifyContent="center" alignItems="center">
        <IconButton size="large" onClick={handlePrevious}>
          <ChevronLeft />
        </IconButton>
        <Typography variant="h6">
          {currentWordIndex + 1}/{cate?.list.length}
        </Typography>
        <IconButton size="large" onClick={handleNext}>
          <ChevronRight />
        </IconButton>
      </Grid>
    </GridWord>
  );
};
